// src/components/WhatsApp/WhatsAppButton.js

import React from 'react';
import './WhatsAppButton.css'; // Import the CSS for styling

const WhatsAppButton = () => {
  const phoneNumber = '918484801861'; // Replace with your WhatsApp number (include country code, e.g., '919876543210' for India)
  const message = encodeURIComponent('Hello! I would like to inquire about...'); // Optional pre-filled message

  return (
    <a
      href={`https://wa.me/${phoneNumber}?text=${message}`}
      className="whatsapp-button"
      target="_blank"
      rel="noopener noreferrer"
      aria-label="Chat on WhatsApp"
    >
      <i className="fab fa-whatsapp"></i>
    </a>
  );
};

export default WhatsAppButton;
